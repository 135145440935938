::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #3E8ED0;
  border-radius: 1rem;
}

html {
  background-color: #EFF3FB !important;
  overflow: hidden !important;
}

.no-radius {
  border-radius: 0px !important;
}

.h-100 {
  height: 100% !important;
}

.h-0 {
  height: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.h-50 {
  height: 50% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-25 {
  width: 25% !important;
}

.w-33 {
  width: 33% !important;
}

.w-65 {
  width: 65% !important;
}

.w-35 {
  width: 35% !important;
}

.c-p {
  cursor: pointer !important;
}

.bg-light-blue {
  background-color: #F0F3FB !important;
}

.bg-hover-blue:hover {
  background-color: #F0F3FB !important;
}

.trim-text {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.is-overflow-y {
  overflow-y: auto;
}

.is-overflow-x {
  overflow-x: auto;
}

.is-overflow {
  overflow: auto;
}

.alias {
  cursor: alias;
}

.all-scroll {
  cursor: all-scroll;
}

.auto {
  cursor: auto;
}

.cell {
  cursor: cell;
}

.col-resize {
  cursor: col-resize;
}

.context-menu {
  cursor: context-menu;
}

.copy {
  cursor: copy;
}

.crosshair {
  cursor: crosshair;
}

.default {
  cursor: default;
}

.e-resize {
  cursor: e-resize;
}

.ew-resize {
  cursor: ew-resize;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.help {
  cursor: help;
}

.move {
  cursor: move;
}

.n-resize {
  cursor: n-resize;
}

.ne-resize {
  cursor: ne-resize;
}

.nesw-resize {
  cursor: nesw-resize;
}

.ns-resize {
  cursor: ns-resize;
}

.nw-resize {
  cursor: nw-resize;
}

.nwse-resize {
  cursor: nwse-resize;
}

.no-drop {
  cursor: no-drop;
}

.none {
  cursor: none;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.progress {
  cursor: progress;
}

.row-resize {
  cursor: row-resize;
}

.s-resize {
  cursor: s-resize;
}

.se-resize {
  cursor: se-resize;
}

.sw-resize {
  cursor: sw-resize;
}

.text {
  cursor: text;
}

.w-resize {
  cursor: w-resize;
}

.wait {
  cursor: wait;
}

.zoom-in {
  cursor: zoom-in;
}

.zoom-out {
  cursor: zoom-out;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.content {
  font: caption;
}

.ribbon {
  height: 120px;
  width: 120px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  content: "";
  z-index: 0;
  display: block;
  border: 7px solid #003140;
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon::before {
  top: 0px;
  right: 12px;
}

.ribbon::after {
  bottom: 11px;
  left: -1px;
}

.ribbon span {
  position: absolute;
  background-color: #2978D7;
  width: 150px;
  padding: 4px 0;
  color: #fff;
  text-align: center;
  top: 30px;
  right: 0px;
  text-transform: uppercase;
  transform: rotate(-45deg);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}